form_validator('#loginform',
    {
        email: {
            required: true,
            email: true
        },
        password: {
            required: true
        }
    },
    {}
);

form_validator('#recoverform',
    {
        email: {
            required: true,
            email: true
        }
    },
    {}
);

if ($('#recoverform').length) {
    $('.send-recover-request').on('click', function () {
        if($('#recoverform').valid()) {
            swal({
                    title: "Success!",
                    text: "Thank you for submitting your request. You will receive an e-mail with instructions for resetting your password shortly.",
                    type: "success",
                    showCancelButton: false,
                    confirmButtonClass: "btn-success",
                    confirmButtonText: "OK",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    $('#recoverform').submit();
                });
        }

    });
}